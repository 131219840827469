import Component from "../../../../shared/js/common/component";
import Mmenu from 'mmenu-js/src/mmenu.js';

export default class MobileNavigation extends Component {
    static readonly componentName: string = "header";

    mmenu: Mmenu;

    init(): void {
        this.initMmenu();
        this.setHeaderFixed();
        this.registerEvents();
    }

    registerEvents() {
        window.addEventListener("scroll", () => {
            this.setHeaderFixed();
        })
    }

    initMmenu() {
        let icon = document.querySelector('#mm-button')
        let template = document.querySelector('.mmenu__footer-template-inner').innerHTML;
        let content = '<div class="mmenu__footer container">' + template + '</div>';

        document.addEventListener("DOMContentLoaded", () => {
            this.mmenu = new Mmenu("#mobile-nav", {
                "extensions": [
                    "position-front",
                    "pagedim-black",
                    "multiline"
                ],
                "navbars": [
                    {
                        position: "bottom",
                        content: [
                            content,
                        ]
                    }
                ],
                "offCanvas" : {
                    pageSelector: "#siteWrapper",
                    position: "left-front"
                },
                hooks: {
                    'open:before': () => {
                        setTimeout(() => {
                            icon.classList.add('is-active');
                        }, 100);
                    },
                    'close:after': () => {
                        setTimeout(() => {
                            icon.classList.remove('is-active');
                        }, 100);
                    }
                }
            })

            let mmenuApi = this.mmenu.API;
            icon.addEventListener('click', () => {
                if (mmenuApi) {
                    mmenuApi.open();
                }
            })

            let links = document.querySelector('.navigation');
            links.addEventListener('click', () => {
                this.mmenu.API.close();
            })
        })

    }

    setHeaderFixed() {
        let windowScroll = window.scrollY;
        let breakpoint = 50;
        let header = document.querySelector('.header')

        if (windowScroll >= breakpoint) {
            header.classList.add('sticky');
        } else {
            header.classList.remove('sticky');
        }
    }

}