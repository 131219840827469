import Component from "../common/component";
import bootstrap from "../../../../../node_modules/bootstrap/js/index.umd.js";

export default class Advantage extends Component {
    static readonly componentName: string = "b-advantage";
    sliderElement: HTMLElement;
    sliderItems: Element[];

    init(): void {
        this.sliderElement = this.element.querySelector('.'+Advantage.componentName+'__slider');
        if (window.screen.width < 576) {
            this.initSlider();
        }
    }

    initSlider() {
        new bootstrap.Carousel(this.sliderElement, {
            interval: 2000,
            wrap: true
        })
    }
}