import Component from "../js/common/component";
import Swiper from "swiper";
import {Navigation, Pagination} from "swiper/modules";
import  'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

export default class News extends Component {
    static readonly componentName: string = "b-news";
    sliderElement: HTMLElement;
    swiper: Swiper;

    init() {
        this.sliderElement = this.element.querySelector('.swiper')
        this.registerEvents();
        window.onresize = () => {
            this.registerEvents();
        }
    }

    registerEvents() {
        this.loadMore();

        if (this.sliderElement) {
            if (window.matchMedia("(max-width: 575px)").matches) {
                this.initSlider();
            } else {
                this.destroySlider();
            }
        }
    }

    loadMore() {
        let loadMoreButton = this.element.querySelector('.b-news__button');
        if (loadMoreButton) {
            loadMoreButton.addEventListener('click', () => {
                let hiddenItems = this.element.querySelectorAll('.hidden-items');
                hiddenItems.forEach((item) => {
                    item.classList.remove('hidden-items');
                });
                loadMoreButton.classList.add('hidden-items');
            });
        }
    }

    initSlider() {
        Swiper.use([Navigation, Pagination]);
        this.swiper = new Swiper(this.sliderElement, {
            loop: true,
            pagination: {
                el: '.swiper-pagination',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            spaceBetween: 20,
            autoHeight: true,
        })
    }

    destroySlider() {
        this.swiper.destroy();
    }

}