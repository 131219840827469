/**
 * Import libraries -> use global typescript file
 */

/**
 * Import modules
 */
import CommonApplication from './../../../shared/js/application';
import 'bootstrap/js/src/carousel.js';

/**
 * Bricks
 */
import Advantage from './bricks/advantage';
import eventTeaser from "./bricks/event-teaser";
import MobileNavigation from "./navigation/mobile-navigation";

/**
 * Application class
 */
class Application extends CommonApplication {
    run() {
        super.run();

        Advantage.initialize();
        eventTeaser.initialize();
        MobileNavigation.initialize();
    }

}

export default Application;