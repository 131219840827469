// const outdatedBrowserRework = require("outdated-browser-rework");

/**
 * Import libraries -> use global typescript file
 */
import 'bootstrap/js/src/collapse.js';
import GLightbox from '../../../node_modules/glightbox/src/js/glightbox.js';
import Interferer from "./interferer";
import News from "./news";

class Application {

    editmode: boolean;

    run() {
        this.initEditMode();
        this.initNoJsClass();
        this.initLightbox();
        this.initInterferer();
        this.initNews();
    }

    initInterferer() {
        Interferer.initialize();
    }

    initNews() {
        News.initialize();
    }

    initLightbox() {
        GLightbox({});
    }

    initEditMode() {
        this.editmode = !!document.body.classList.contains('editmode');
    }

    initNoJsClass() {
        document.getElementsByTagName('html')[0].className = document.getElementsByTagName('html')[0].className.replace(/no-js/, '');
    }

}

export default Application;