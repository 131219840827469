import Component from "../common/component";

export default class eventTeaser extends Component {
    static readonly componentName: string = "b-event-teaser";

    loadMoreButton: HTMLElement;
    eventWrapper: HTMLElement;

    init() {
        this.loadMoreButton = this.element.querySelector('.b-event-teaser__load-more-button');
        this.eventWrapper = this.element.querySelector('.event-table');

        this.registerEvents();
    }

    registerEvents() {
        this.loadMore();
    }

    loadMore() {
        this.loadMoreButton?.addEventListener('click', () => {
            this.eventWrapper.classList.add('show-all');
            this.loadMoreButton.classList.add('d-none');
        });
    }

}