import Component from "../js/common/component";

export default class Interferer extends Component {
    static readonly componentName: string = "interferer";

    cookieExpires: string
    cookieName: string
    closeButton: HTMLElement
    popUpOverlay: HTMLElement

    init(): void {
        this.cookieName = 'showInterfererOverlay';
        const d = new Date();
        d.setTime(d.getTime() + (60*60*24));
        this.cookieExpires = d.toUTCString();
        this.closeButton = this.element.querySelector('.'+Interferer.componentName+'-close');
        this.popUpOverlay = this.element.querySelector('#popUpOverlay');
        this.bindEvents();
        this.showInterferer();
    }

    bindEvents() {
        this.closeButton.addEventListener("click", () => {
            this.setCookie(this.cookieName, true, this.cookieExpires);
            this.hideInterferer();
        });
    }

    hideInterferer() {
        this.element.classList.add("d-none");
    }

    showInterferer() {
        this.element.classList.remove('d-none');
        if (!this.getCookie(this.cookieName)) {
            if (this.popUpOverlay != null) {
                if (this.popUpOverlay.classList.contains("d-none")) {
                    this.element.classList.remove('d-none');
                } else {
                    // Options for the observer (which mutations to observe)
                    const config = {attributes: true, childList: true, subtree: true};

                    // Callback function to execute when mutations are observed
                    // Create an observer instance linked to the callback function
                    const observer = new MutationObserver((mutationsList, observer) => {

                            // Use traditional 'for loops' for IE 11
                            for (let mutation of mutationsList) {
                                if (mutation.type === 'attributes') {
                                    if (mutation.attributeName === 'd-none') {
                                        this.element.classList.remove('d-none');
                                        observer.disconnect();
                                    }
                                }
                            }
                        }
                    );
                    observer.observe(this.popUpOverlay, config);
                }
            }
            else {
                this.element.classList.remove('d-none');
            }
        }
    }

    setCookie(name, value, expires, path = '/'){
        document.cookie = document.cookie =  name + '=' + value + ';expires=' + expires + ';path=' + path;
    }

    getCookie(name) {
        const value = "; " + document.cookie;
        const parts = value.split("; " + name + "=");
        if (parts.length === 2){
            return parts.pop().split(";").shift();
        }

        return null;
    }
}