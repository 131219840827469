export default class Component {

    static readonly componentName: string;

    element: HTMLElement;

    constructor() {
    }

    static initialize(): void {

        const elements = document.getElementsByClassName(this.componentName);

        Array.from(elements).forEach((element: HTMLElement) => {
            const instance = new this();
            instance.element = element;

            // @ts-ignore
            if (!element.componentInstance) {
                // @ts-ignore
                element.componentInstance = instance;
            }

            instance.onDocumentReady();
        });

    }

    onDocumentReady(): void {
        this.init();
    }

    init(): void {
    }

}